<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
export default {
    components: {
        Header,
        Footer,
    },
};
</script>

<template>
    <main class="container_black">
        <Header />
        <section class="header_bd">
            <h1 class="ta_center">Terms</h1>
            <p class="ta_center header_article">
                Please read the following terms carefully before using Rivet. By using Rivet, you are agreeing to be bound by the following Terms of
                Use.
            </p>
        </section>
        <main class="child_wrap terms&c">
            <div class="termsofuse">
                <div class="header_2">
                    <h2 class="ta_center">Terms of use</h2>
                    <div class="txt">
                        <p class="highlight header_article ta_center">
                            THIS AGREEMENT IS SUBJECT TO BINDING ARBITRATION AND A WAIVER OF CLASS ACTION RIGHTS AS DETAILED IN SECTION 13. PLEASE
                            READ THE AGREEMENT CAREFULLY.
                        </p>
                        <p class="ta_center">
                            <span class="highlight">Our Terms of Use have been updated as of July 13, 2019</span>
                        </p>
                    </div>
                </div>
                <div class="item_rivet_terms flex_col">
                    <div class="item_terms">
                        <h2>1. Acceptance of Terms</h2>
                        <div class="txt">
                            <p>
                                Rivet ("Rivet") provides access to cloud-hosted blockchain infrastructure enabling subscribers to programmatically
                                interact with supported blockchains (the "Service") by subscribing through
                                <a href="https://rivet.cloud ">https://rivet.cloud </a>
                                (the "Website")— including text, images, code and other materials (collectively, the “Content”) and all of the
                                features and services provided. The Service, and any other features, tools, materials, or other services offered from
                                time to time by Rivet are referred to here as the “Service.” Please read these Terms of Use (the “Terms” or “Terms of
                                Use”) carefully before using the Service. By using or otherwise accessing the Services, or clicking to accept or agree
                                to these Terms where that option is made available, you accept and agree to these Terms. If you do not agree to the
                                Terms, then you may not access or use the Content or Services.
                            </p>
                        </div>
                    </div>
                    <div class="item_terms">
                        <h2>2. Modification of Terms of Use</h2>
                        <div class="txt">
                            <p>
                                Except for Section 13, providing for binding arbitration and waiver of class action rights, Rivet reserves the right,
                                at its sole discretion, to modify or replace the Terms of Use at any time. The most current version of these Terms
                                will be posted on our Service. You shall be responsible for reviewing and becoming familiar with any such
                                modifications. Use of the Services by you after any modification to the Terms constitutes your acceptance of the Terms
                                of Use as modified.
                            </p>
                        </div>
                    </div>
                    <div class="item_terms">
                        <h2>3. Eligibility</h2>
                        <div class="txt">
                            <p>
                                You hereby represent and warrant that you are fully able and competent to enter into the terms, conditions,
                                obligations, affirmations, representations and warranties set forth in these Terms and to abide by and comply with
                                these Terms
                            </p>
                            <p>
                                Rivet is a global platform and by accessing the Content or Services, you are representing and warranting that, you are
                                of the legal age of majority in your jurisdiction as is required to access such Services and Content and enter into
                                arrangements as provided by the Service. You further represent that you are otherwise legally permitted to use the
                                service in your jurisdiction including owning cryptographic tokens of value, and interacting with the Services or
                                Content in any way. You further represent you are responsible for ensuring compliance with the laws of your
                                jurisdiction and acknowledge that Rivet is not liable for your compliance with such laws.
                            </p>
                        </div>
                    </div>
                    <div class="item_terms">
                        <h2>4. Representations, Warranties, and Risks</h2>
                        <div class="item_child_sub">
                            <h3>4.1. Warranty Disclaimer</h3>
                            <div class="txt">
                                <p>
                                    You expressly understand and agree that your use of the Service is at your sole risk. The Service (including the
                                    Service and the Content) are provided on an "AS IS" and "as available" basis, without warranties of any kind,
                                    either express or implied, including, without limitation, implied warranties of merchantability, fitness for a
                                    particular purpose or non-infringement. You acknowledge that Rivet has no control over, and no duty to take any
                                    action regarding: which users gain access to or use the Service; what effects the Content may have on you; how you
                                    may interpret or use the Content; or what actions you may take as a result of having been exposed to the Content.
                                    You release Rivet from all liability for you having acquired or not acquired Content through the Service. Rivet
                                    makes no representations concerning any Content contained in or accessed through the Service, and Rivet will not
                                    be responsible or liable for the accuracy, copyright compliance, legality or decency of material contained in or
                                    accessed through the Service.
                                </p>
                            </div>
                        </div>
                        <div class="item_child_sub">
                            <h3>4.2. Sophistication and Risk of Cryptographic Systems</h3>
                            <div class="txt">
                                <p>
                                    By utilizing the Service or interacting with the Content or platform in any way, you represent that you understand
                                    the inherent risks associated with cryptographic systems; and warrant that you have an understanding of the usage
                                    and intricacies of native cryptographic tokens, like Ether (ETH) and Bitcoin (BTC), smart contract based tokens
                                    such as those that follow the Ethereum Token Standard and blockchain-based software systems.
                                </p>
                            </div>
                        </div>
                        <div class="item_child_sub">
                            <h3>4.3. Risk of Regulatory Actions in One or More Jurisdictions</h3>
                            <div class="txt">
                                <p>
                                    Rivet and ETH could be impacted by one or more regulatory inquiries or regulatory action, which could impede or
                                    limit the ability of Rivet to continue to develop, or which could impede or limit your ability to access or use
                                    the Service or Ethereum blockchain.
                                </p>
                            </div>
                        </div>
                        <div class="item_child_sub">
                            <h3>4.4. Risk of Weaknesses or Exploits in the Field of Cryptography</h3>
                            <div class="txt">
                                <p>
                                    You acknowledge and understand that Cryptography is a progressing field. Advances in code cracking or technical
                                    advances such as the development of quantum computers may present risks to cryptocurrencies and Services of
                                    Content, which could result in the theft or loss of your cryptographic tokens or property. To the extent possible,
                                    Rivet intends to update the protocol underlying Services to account for any advances in cryptography and to
                                    incorporate additional security measures, but does not guarantee or otherwise represent full security of the
                                    system. By using the Service or accessing Content, you acknowledge these inherent risks.
                                </p>
                            </div>
                        </div>
                        <div class="item_child_sub">
                            <h3>4.5 Volatility of Crypto Currencies</h3>
                            <div class="txt">
                                <p>
                                    You understand that Ethereum and other blockchain technologies and associated currencies or tokens are highly
                                    volatile due to many factors including but not limited to adoption, speculation, technology and security risks.
                                    You also acknowledge that the cost of transacting on such technologies is variable and may increase at any time
                                    causing impact to any activities taking place on the Ethereum blockchain. You acknowledge these risks and
                                    represent that Rivet cannot be held liable for such fluctuations or increased costs.
                                </p>
                            </div>
                        </div>
                        <div class="item_child_sub">
                            <h3>4.6 Application Security</h3>
                            <div class="txt">
                                <p>
                                    You acknowledge that Ethereum applications are code subject to flaws and acknowledge that you are solely
                                    responsible for evaluating any code provided by the Services or Content and the trustworthiness of any third-party
                                    websites, products, smart-contracts, or Content you access or use through the Service. You further expressly
                                    acknowledge and represent that Ethereum applications can be written maliciously or negligently, that Rivet cannot
                                    be held liable for your interaction with such applications and that such applications may cause the loss of
                                    property or even identity. This warning and others later provided by Rivet in no way evidence or represent an
                                    on-going duty to alert you to all of the potential risks of utilizing the Service or Content.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="item_terms">
                        <h2>5. Indemnity</h2>
                        <div class="txt">
                            <p>
                                You agree to release and to indemnify, defend and hold harmless Rivet and its parents, subsidiaries, affiliates and
                                agencies, as well as the officers, directors, employees, shareholders and representatives of any of the foregoing
                                entities, from and against any and all losses, liabilities, expenses, damages, costs (including attorneys’ fees and
                                court costs) claims or actions of any kind whatsoever arising or resulting from your use of the Service, your
                                violation of these Terms of Use, and any of your acts or omissions that implicate publicity rights, defamation or
                                invasion of privacy. Rivet reserves the right, at its own expense, to assume exclusive defense and control of any
                                matter otherwise subject to indemnification by you and, in such case, you agree to cooperate with Rivet in the defense
                                of such matter.
                            </p>
                        </div>
                    </div>
                    <div class="item_terms">
                        <h2>6. Limitation on liability</h2>
                        <div class="txt">
                            <p>
                                <span class="highlight">
                                    YOU ACKNOWLEDGE AND AGREE THAT YOU ASSUME FULL RESPONSIBILITY FOR YOUR USE OF THE SITE AND SERVICE. YOU
                                    ACKNOWLEDGE AND AGREE THAT ANY INFORMATION YOU SEND OR RECEIVE DURING YOUR USE OF THE SITE AND SERVICE MAY NOT BE
                                    SECURE AND MAY BE INTERCEPTED OR LATER ACQUIRED BY UNAUTHORIZED PARTIES. YOU ACKNOWLEDGE AND AGREE THAT YOUR USE
                                    OF THE SITE AND SERVICE IS AT YOUR OWN RISK. RECOGNIZING SUCH, YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST
                                    EXTENT PERMITTED BY APPLICABLE LAW, NEITHER Rivet NOR ITS SUPPLIERS OR LICENSORS WILL BE LIABLE TO YOU FOR ANY
                                    DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, EXEMPLARY OR OTHER DAMAGES OF ANY KIND, INCLUDING
                                    WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER TANGIBLE OR INTANGIBLE LOSSES OR ANY
                                    OTHER DAMAGES BASED ON CONTRACT, TORT, STRICT LIABILITY OR ANY OTHER THEORY (EVEN IF Rivet HAD BEEN ADVISED OF THE
                                    POSSIBILITY OF SUCH DAMAGES), RESULTING FROM THE SITE OR SERVICE; THE USE OR THE INABILITY TO USE THE SITE OR
                                    SERVICE; UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; STATEMENTS OR CONDUCT OF ANY THIRD
                                    PARTY ON THE SITE OR SERVICE; ANY ACTIONS WE TAKE OR FAIL TO TAKE AS A RESULT OF COMMUNICATIONS YOU SEND TO US;
                                    HUMAN ERRORS; TECHNICAL MALFUNCTIONS; FAILURES, INCLUDING PUBLIC UTILITY OR TELEPHONE OUTAGES; OMISSIONS,
                                    INTERRUPTIONS, LATENCY, DELETIONS OR DEFECTS OF ANY DEVICE OR NETWORK, PROVIDERS, OR SOFTWARE (INCLUDING, BUT NOT
                                    LIMITED TO, THOSE THAT DO NOT PERMIT PARTICIPATION IN THE SERVICE); ANY INJURY OR DAMAGE TO COMPUTER EQUIPMENT;
                                    INABILITY TO FULLY ACCESS THE SITE OR SERVICE OR ANY OTHER WEBSITE; THEFT, TAMPERING, DESTRUCTION, OR UNAUTHORIZED
                                    ACCESS TO, IMAGES OR OTHER CONTENT OF ANY KIND; DATA THAT IS PROCESSED LATE OR INCORRECTLY OR IS INCOMPLETE OR
                                    LOST; TYPOGRAPHICAL, PRINTING OR OTHER ERRORS, OR ANY COMBINATION THEREOF; OR ANY OTHER MATTER RELATING TO THE
                                    SITE OR SERVICE.
                                </span>
                            </p>
                            <p>
                                SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR
                                INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                            </p>
                        </div>
                    </div>
                    <div class="item_terms">
                        <h2>7. Termination and Suspension</h2>
                        <div class="txt">
                            <p>
                                Rivet may terminate or suspend all or part of the Service and your Rivet access immediately, without prior notice or
                                liability, if you breach any of the terms or conditions of the Terms. Upon termination of your access, your right to
                                use the Service will immediately cease.
                            </p>
                            <p>
                                <span class="highlight">
                                    The following provisions of the Terms survive any termination of these Terms: INDEMNITY; WARRANTY DISCLAIMERS;
                                    LIMITATION ON LIABILITY; OUR PROPRIETARY RIGHTS; LINKS; TERMINATION; NO THIRD PARTY BENEFICIARIES; BINDING
                                    ARBITRATION AND CLASS ACTION WAIVER; GENERAL INFORMATION.
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="item_terms">
                        <h2>8. No Third Party Beneficiaries</h2>
                        <div class="txt">
                            <p>
                                <span class="highlight">
                                    You agree that, except as otherwise expressly provided in these Terms, there shall be no third party beneficiaries
                                    to the Terms.
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="item_terms">
                        <h2>9. Binding Arbitration and Class Action Waiver</h2>
                    </div>
                    <div class="item_terms">
                        <h2>
                            10. PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A
                            LAWSUIT IN COURT
                        </h2>

                        <div class="item_child_sub">
                            <h3>10.1. Initial Dispute Resolution</h3>
                            <div class="txt">
                                <p>
                                    The parties shall use their best efforts to engage directly to settle any dispute, claim, question, or
                                    disagreement and engage in good faith negotiations which shall be a condition to either party initiating a lawsuit
                                    or arbitration.
                                </p>
                            </div>
                        </div>
                        <div class="item_child_sub">
                            <h3>10.2. Binding Arbitration</h3>
                            <div class="txt">
                                <p>
                                    If the parties do not reach an agreed upon solution within a period of 30 days from the time informal dispute
                                    resolution under the Initial Dispute Resolution provision begins, then either party may initiate binding
                                    arbitration as the sole means to resolve claims, subject to the terms set forth below. Specifically, all claims
                                    arising out of or relating to these Terms (including their formation, performance and breach), the parties’
                                    relationship with each other and/or your use of the Service shall be finally settled by binding arbitration
                                    administered by the American Arbitration Association in accordance with the provisions of its Commercial
                                    Arbitration Rules and the supplementary procedures for consumer related disputes of the American Arbitration
                                    Association (the "AAA"), excluding any rules or procedures governing or permitting class actions.
                                </p>
                                <p>
                                    The arbitrator, and not any federal, state or local court or agency, shall have exclusive authority to resolve all
                                    disputes arising out of or relating to the interpretation, applicability, enforceability or formation of these
                                    Terms, including, but not limited to any claim that all or any part of these Terms are void or voidable, or
                                    whether a claim is subject to arbitration. The arbitrator shall be empowered to grant whatever relief would be
                                    available in a court under law or in equity. The arbitrator’s award shall be written, and binding on the parties
                                    and may be entered as a judgment in any court of competent jurisdiction.
                                </p>
                                <p>
                                    The parties understand that, absent this mandatory provision, they would have the right to sue in court and have a
                                    jury trial. They further understand that, in some instances, the costs of arbitration could exceed the costs of
                                    litigation and the right to discovery may be more limited in arbitration than in court.
                                </p>
                            </div>
                        </div>
                        <div class="item_child_sub">
                            <h3>10.3. Location</h3>
                            <div class="txt">
                                <p>
                                    Binding arbitration shall take place in Kansas. You agree to submit to the personal jurisdiction of any federal or
                                    state court in Johnson County, Kansas, in order to compel arbitration, to stay proceedings pending arbitration, or
                                    to confirm, modify, vacate or enter judgment on the award entered by the arbitrator.
                                </p>
                            </div>
                        </div>
                        <div class="item_child_sub">
                            <h3>10.4. Class Action Waiver</h3>
                            <div class="txt">
                                <p>
                                    The parties further agree that any arbitration shall be conducted in their individual capacities only and not as a
                                    class action or other representative action, and the parties expressly waive their right to file a class action or
                                    seek relief on a class basis. YOU AND Rivet AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS
                                    INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
                                    If any court or arbitrator determines that the class action waiver set forth in this paragraph is void or
                                    unenforceable for any reason or that an arbitration can proceed on a class basis, then the arbitration provision
                                    set forth above shall be deemed null and void in its entirety and the parties shall be deemed to have not agreed
                                    to arbitrate disputes.
                                </p>
                            </div>
                        </div>
                        <div class="item_child_sub">
                            <h3>10.5. Exception - Litigation of Intellectual Property and Small Claims Court Claims</h3>
                            <div class="txt">
                                <p>
                                    Notwithstanding the parties' decision to resolve all disputes through arbitration, either party may bring an
                                    action in state or federal court to protect its intellectual property rights ("intellectual property rights" means
                                    patents, copyrights, moral rights, trademarks, and trade secrets, but not privacy or publicity rights). Either
                                    party may also seek relief in a small claims court for disputes or claims within the scope of that court’s
                                    jurisdiction.
                                </p>
                            </div>
                        </div>
                        <div class="item_child_sub">
                            <h3>10.6. 30-Day Right to Opt Out</h3>
                            <div class="txt">
                                <p>
                                    You have the right to opt-out and not be bound by the arbitration and class action waiver provisions set forth
                                    above by sending written notice of your decision to opt-out to the following via contact information posted at
                                    support@rivet.cloud. The notice must be sent within 30 days of your first use of the Service, otherwise you shall
                                    be bound to arbitrate disputes in accordance with the terms of those paragraphs. If you opt-out of these
                                    arbitration provisions, Rivet also will not be bound by them.
                                </p>
                            </div>
                        </div>
                        <div class="item_child_sub">
                            <h3>10.7. Changes to This Section</h3>
                            <div class="txt">
                                <p>
                                    Rivet will provide 60-days’ notice of any changes to this section. Changes will become effective on the 60th day,
                                    and will apply prospectively only to any claims arising after the 60th day.
                                </p>
                                <p>
                                    For any dispute not subject to arbitration you and Rivet agree to submit to the personal and exclusive
                                    jurisdiction of and venue in the federal and state courts located in California, California. You further agree to
                                    accept service of process by mail, and hereby waive any and all jurisdictional and venue defenses otherwise
                                    available.
                                </p>
                                <p>
                                    The Terms and the relationship between you and Rivet shall be governed by the laws of the State of Kansas without
                                    regard to conflict of law provisions.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="item_terms">
                        <h2>11. General Information</h2>
                        <div class="item_child_sub">
                            <h3>11.1. Entire Agreement</h3>
                            <div class="txt">
                                <p>
                                    These Terms (and any additional terms, rules and conditions of participation that Rivet may post on the Service)
                                    constitute the entire agreement between you and Rivet with respect to the Service and supersedes any prior
                                    agreements, oral or written, between you and Rivet. In the event of a conflict between these Terms and the
                                    additional terms, rules and conditions of participation, the latter will prevail over the Terms to the extent of
                                    the conflict.
                                </p>
                            </div>
                        </div>
                        <div class="item_child_sub">
                            <h3>11.2. Waiver and Severability of Terms</h3>
                            <div class="txt">
                                <p>
                                    The failure of Rivet to exercise or enforce any right or provision of the Terms shall not constitute a waiver of
                                    such right or provision. If any provision of the Terms is found by an arbitrator or court of competent
                                    jurisdiction to be invalid, the parties nevertheless agree that the arbitrator or court should endeavor to give
                                    effect to the parties' intentions as reflected in the provision, and the other provisions of the Terms remain in
                                    full force and effect.
                                </p>
                            </div>
                        </div>
                        <div class="item_child_sub">
                            <h3>11.3. Statute of Limitations</h3>
                            <div class="txt">
                                <p>
                                    You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or
                                    related to the use of the Service or the Terms must be filed within one (1) year after such claim or cause of
                                    action arose or be forever barred.
                                </p>
                            </div>
                        </div>
                        <div class="item_child_sub">
                            <h3>11.4. Section Titles</h3>
                            <div class="txt">
                                <p>The section titles in the Terms are for convenience only and have no legal or contractual effect.</p>
                            </div>
                        </div>
                        <div class="item_child_sub">
                            <h3>11.5. Communications</h3>
                            <div class="txt">
                                <p>Users with questions, complaints or claims with respect to the Service may contact us at support@rivet.cloud.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <Footer />
    </main>
</template>

<style lang="scss" scoped>
.highlight {
    color: white;
}
h2 {
    color: $grey;
}
p {
    line-height: 2rem;
    a {
        color: $blue;
    }
}
.termsofuse {
    padding: 5rem 0px;
    @include flex_col(8rem 0px);
    @include media("<=tablet") {
        gap: 6.5rem 0;
    }
    .header_2 {
        @include flex_col(1.5rem 0px);
    }
    .item_rivet_terms {
        gap: 4rem 0px;
        .item_terms {
            @include flex_col(1.2rem 0px);
            .item_child_sub {
                @include flex_col(1.2rem 0px);
            }
        }
    }
}
.header_bd {
    @include flex_col(1.5rem 0px);
}
.header_article {
    max-width: 50%;
    margin: 0 auto;
    @include media("<=tablet") {
        max-width: 70%;
    }
    @include media("<=phone-tab") {
        max-width: 90%;
    }
}
</style>
